
export default {
  name: 'Disclosure',
  data() {
    return {
      decoratedList: [],
    }
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  watch: {
    list: {
      handler(newValue) {
        this.decoratedList = newValue.map((i, index) => ({ ...i, isOpen: index === 0 }))
      },
      immediate: true,
      deep: true,
    },
  },
}
