
export default {
  props: {
    detectedContent: {
      type: Array,
      default: () => [],
    },
    content: {
      type: Array,
      required: true,
    },
  },
}
