
import { getSliceComponentProps } from '@prismicio/vue/components'

export default {
  name: 'CaseStudies',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    caseStudies() {
      console.log(this.context?.caseStudies)
      return this.slice.items.reduce((cases, item) => {
        const selected = this.context?.caseStudies?.data.find((b) =>
          item.case_study_url.toLowerCase().includes(b.slug),
        )
        if (selected) {
          cases.push(selected)
        }
        return cases
      }, [])
    },
  },
}
