
import { getSliceComponentProps } from '@prismicio/vue/components'

export default {
  name: 'TestimonialsHomepage',
  // The array passed to `getSliceComponentProps` is purely optional and acts as a visual hint for you
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  data() {
    return {
      observer: null,
    }
  },
  mounted() {
    // const cardList = document.querySelectorAll('.testimonial-card')
    // cardList.forEach((i) => i.classList.remove('card-animation'))
    //
    // this.observer = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       cardList.forEach((i) => i.classList.add('card-animation'))
    //       return
    //     }
    //
    //     cardList.forEach((i) => i.classList.remove('card-animation'))
    //   })
    // })
    //
    // this.observer.observe(document.querySelector('.testimonialsGrid'))
  },
}
