
export default {
  props: {
    as: {
      type: String,
      default: 'a',
    },
    kind: {
      type: String,
      default: 'default',
      validator: (v) => ['default', 'outline'].includes(v),
    },
    size: {
      type: String,
      default: "base",
      validator: (v) => ["base", "lg"].includes(v),
    },
    color: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    hover: {
      type: String,
      default: '',
    },
  },
}
