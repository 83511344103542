
import { components } from '~/slices'

export default {
  // async asyncData({ $prismic }) {
  //   const { data } = await $prismic.api.getSingle('homepage')
  //   const { title, slices, accent_color: accentColor, custom_calendly: pageLevelCalendly } = data
  //   return { title, slices, accentColor, pageLevelCalendly }
  // },

  // data() {
  //   return {
  //     components,
  //     domain: '',
  //     sdrName: '',
  //   }
  // },

  // head() {
  //   return {
  //     title: `${this.$prismic.asText(this.title)}`,
  //   }
  // },

  created() {
    /* eslint-disable nuxt/no-globals-in-created */
    if (process.client) {
      this.domain = window.location.hostname
      if (window.location.hostname === 'abm.influ2.com') {
        window.location.replace('https://www.influ2.com/')
      }
    }
  },

  // mounted() {
  //   if (this.pageLevelCalendly) {
  //     this.$store.commit('PUT_CALENDLY_LINK', this.pageLevelCalendly)
  //   }
  //   this.selectSdr()
  //   this.selectAccentColor()
  //   window.addEventListener('hashchange', this.selectSdr)
  // },

  // destroyed() {
  //   window.removeEventListener('hashchange', this.selectSdr)
  // },

  // methods: {
  //   selectSdr() {
  //     if (window.location.hash) {
  //       this.sdrName = window.location.hash.substring(1)
  //     }
  //   },

  //   selectAccentColor() {
  //     const headings = document.querySelectorAll('.textBlock h2')
  //     const links = document.querySelectorAll('.text--container a.hyperlink')
  //     ;[...headings, ...links].forEach((el) => {
  //       el.style.color = this.accentColor || '#6242ff'
  //     })
  //   },
  // },
}
