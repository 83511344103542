
import { getSliceComponentProps } from '@prismicio/vue/components'

export default {
  name: 'TimeCodes',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  data() {
    return {
      selected: 0,
    }
  },

  computed: {
    hasTranscript() {
      return this.slice.items.some((item) => {
        return item.transcript.some((i) => i.text)
      })
    },
  },
}
