
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  head() {
    return {
      link: [
        { rel: 'preconnect', href: 'https://www.influ2.com' },
        { rel: 'preconnect', href: 'https://static.cdn.prismic.io' },
      ],
      script: [
        {
          src: 'https://static.cdn.prismic.io/prismic.js?new=true&repo=influ2-lp',
          async: true,
          defer: true,
        },
      ],
    }
  },
  mounted() {
    const trackingCodes = [
      '409131dd-49e5-4e7c-87de-797edc607db0&amp;d=3',
      '00df510f-dc51-4743-bf92-0623b8b662c0',
    ]
    trackingCodes.forEach((code) => {
      const script = document.createElement('script')
      script.setAttribute('src', '//www.influ2.com/tracker?clid=' + code)
      document.head.appendChild(script)
    })

    AOS.init({
      offset: 100,
      delay: 4,
      duration: 800,
      easing: 'ease-out-quad',
      once: !0,
    })
    window.addEventListener('load', AOS.refresh)
  },
  updated() {
    AOS.refresh()
  },
  destroyed() {
    window.removeEventListener('load', AOS.refresh)
  },
}
