import { render, staticRenderFns } from "./index.vue?vue&type=template&id=fd45a35e&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=fd45a35e&prod&lang=postcss&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&id=fd45a35e&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd45a35e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RichText: require('/opt/build/repo/components/RichText.vue').default,Button: require('/opt/build/repo/components/Button.vue').default,Bounded: require('/opt/build/repo/components/Bounded.vue').default})
