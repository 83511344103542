
export default {
  name: 'NuxtError',
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.collectError()
  },
  methods: {
    collectError() {
      try {
        fetch('/.netlify/functions/errors', {
          method: 'POST',
          body: JSON.stringify({
            host: window.location.host,
            path: window.location.href,
            datetime: new Date().toUTCString(),
            referrer: document.referrer,
          }),
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
}
