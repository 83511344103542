
export default {
  name: 'CardCase',
  props: {
    entry: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: '',
    },
    kind: {
      type: String,
      default: 'default',
      validator: (v) => ['default', 'thumbnail'].includes(v),
    },
  },
}
