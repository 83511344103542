// Code generated by Slice Machine. DO NOT EDIT.

import CaseStudies from './CaseStudies';
import Content from './Content';
import Cta from './Cta';
import Footer from './Footer';
import Header from './Header';
import Hero from './Hero';
import HeroHomepage from './HeroHomepage';
import Image from './Image';
import Integrations from './Integrations';
import KeyTakeaways from './KeyTakeaways';
import Logos from './Logos';
import Qa from './Qa';
import Quote from './Quote';
import ScrollableCards from './ScrollableCards';
import Speakers from './Speakers';
import TestimonialsHomepage from './TestimonialsHomepage';
import Text from './Text';
import TimeCodes from './TimeCodes';
import TwoColumn from './TwoColumn';
import TwoColumnHomepage from './TwoColumnHomepage';

export {
	CaseStudies,
	Content,
	Cta,
	Footer,
	Header,
	Hero,
	HeroHomepage,
	Image,
	Integrations,
	KeyTakeaways,
	Logos,
	Qa,
	Quote,
	ScrollableCards,
	Speakers,
	TestimonialsHomepage,
	Text,
	TimeCodes,
	TwoColumn,
	TwoColumnHomepage,
};

export const components = {
	case_studies: CaseStudies,
	rich_content: Content,
	cta: Cta,
	footer: Footer,
	header: Header,
	hero: Hero,
	hero_homepage: HeroHomepage,
	image: Image,
	integrations: Integrations,
	key_takeaways: KeyTakeaways,
	logos: Logos,
	qa: Qa,
	quote: Quote,
	scrollable_cards: ScrollableCards,
	speakers: Speakers,
	testimonials_homepage: TestimonialsHomepage,
	text: Text,
	time_codes: TimeCodes,
	two_column: TwoColumn,
	two_column_homepage: TwoColumnHomepage,
};
