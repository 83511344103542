import { render, staticRenderFns } from "./Disclosure.vue?vue&type=template&id=425a25a8&scoped=true&"
import script from "./Disclosure.vue?vue&type=script&lang=js&"
export * from "./Disclosure.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "425a25a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RichText: require('/opt/build/repo/components/RichText.vue').default})
