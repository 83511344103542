
export default {
  name: 'Hero',
  components: {
    heroTwoColumn: () => import('./HeroTwoColumn.vue'),
    heroVideo: () => import('./HeroVideo.vue'),
    heroTwoContentColumn: () => import('./HeroTwoContentColumn.vue'),
    heroSdr: () => import('./HeroSdr.vue'),
    default: () => import('./Default.vue'),
  },
}
