
export default {
  props: {
    increment: {
      type: Number,
      default: 340
    }
  },
  data() {
    return {
      isShowLeftBtn: false,
      isShowRightBtn: false,
      lastScrollLeft: 0
    };
  },
  mounted() {
    this.onScrollCards();
  },
  methods: {
    onScrollCards() {
      const scrollableElement = document.getElementById("scrollWrapper");
      this.isShowLeftBtn = scrollableElement.scrollLeft >= 30;

      const maxScrollLeft = scrollableElement.scrollWidth - scrollableElement.clientWidth;
      const currentScrollLeft = Math.ceil(scrollableElement.scrollLeft);

      this.isShowRightBtn = !(scrollableElement.scrollWidth === scrollableElement.clientWidth) &&
        !(currentScrollLeft >= maxScrollLeft && currentScrollLeft > this.lastScrollLeft);
    },
    onClickLeft() {
      const scrollableSection = document.getElementById("scrollWrapper");
      scrollableSection.scrollLeft -= this.increment;
    },
    onClickRight() {
      const scrollableSection = document.getElementById("scrollWrapper");
      scrollableSection.scrollLeft += this.increment;
    }
  }
};
