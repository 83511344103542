
export default {
  props: {
    as: {
      type: [String, Object, Function],
      default: 'div',
    },
    size: {
      type: String,
      default: 'widest',
    },
    animate: {
      type: Boolean,
      default: true
    }
  },
}
